import Axios from "axios"
import { SERVER_URL } from "./Settings"
import { logout } from './auth'


/*function getCsrfToken() {
	const csrfCookie = document.cookie.match(/csrftoken=([^;]+)/)
	return csrfCookie ? csrfCookie[1] : null
}*/

const api = Axios.create({
    baseURL: SERVER_URL,
    timeout: 60000,
    withCredentials: true,
	/*headers: {
		'X-CSRFToken': getCsrfToken(),
	},*/
})

// Add a request interceptor
/*api.interceptors.request.use(
	config => {
		// You can modify request headers here if needed
		return config
	},
	error => {
		return Promise.reject(error)
	}
)*/
  
// Add a response interceptor
api.interceptors.response.use(
response => {
	// If the response is successful, just return it
	return response
},
error => {
	// If the response status is 401 or 403, trigger logout
	if (error.response.status === 401/* || error.response.status === 403*/) {
		// Trigger your logout function here
		logout()
	}
	return Promise.reject(error)
}
)

export default api
