import { useEffect, useRef, useContext } from "react"
import { useParams, NavLink } from "react-router-dom"
import { useImmer } from "use-immer"
import { Spin, List, Pagination, Space, Button, Slider, Tooltip, Input, Checkbox } from 'antd'
import { FireOutlined, FireFilled, StopOutlined } from '@ant-design/icons'
import SideMenu from './SideMenu'
import ReachScreenOffers from "./ReachScreenOffers"
import Login from "./Login"
import { cropText, getLocalTime } from "../Utils"
import { useFetchSalesmen } from "./DataFetcher"
import AntSelect from "./AntSelect"
import GenericWarningModal from "./GenericWarningModal"

import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

const { Search } = Input


function ReachScreen(props) {
    const { messageIds } = useParams()

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    function getStoredVal(variableName, defaultValue) {
        const val = localStorage.getItem(variableName)
        if (val === null || val === undefined) {
            return defaultValue
        }
        return val
    }

    const [dataSource, setDataSource] = useImmer({
        offerMessages: [],
        pagination: {current:1, pageSize:20, total:0, showSizeChanger: false, /*pageSizeOptions: ['10', '20'], position: ["topRight", ], */},
        isLoading: false,
        selectedItemIndex: 0,
        requestCount: 0,
        salesmen: {data:[], selected:-1, requestCount:0, isLoading:false},
        isRefresh: true,
        searchText: "",
        timeRange: "Today",
        minNumGroups: getStoredVal("dc_minNumGroups", 50),
        minNumDMs: getStoredVal("dc_minNumDMs", 50),
        msgSimThr: getStoredVal("dc_msgSimThr", 0.8),
        msgSimThrSent: getStoredVal("dc_msgSimThr", 0.8),
        selectedMessageIds: [],
        showSelectedMessages: false,
        firedMessageIds: [],
        fireData: {msgId:-1, isFire:false, requestCount:0},
        firedFilter: false,
        ignoredMessageIds: [],
        ignoreData: {msgId:-1, isIgnore:false, requestCount:0},
        relevantOnly: false,
        waIdDict: null,
    })

    const listRef = useRef(null)

    const [genericWarningData, setGenericWarningData] = useImmer({title:"", modal: {isOpen: false}})

    useFetchSalesmen(appState, appDispatch, dataSource, setDataSource) // DataFetcher

    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchOfferMessages() {
            if (!appState.loggedIn) return

            setDataSource(draft => {
                draft.isLoading = true
            })

            try {
                let url = 'list_offer_messages/'
                url += `?page_size=${dataSource.pagination.pageSize}`
                url += `&page=${dataSource.pagination.current}`

                url += `&msg_sim_thr=${dataSource.msgSimThrSent}`

                url += `&time_range=${dataSource.timeRange}`

                if (dataSource.firedFilter) {
                    url += '&is_hot_only=true'
                }

                if (dataSource.relevantOnly) {
                    url += '&is_relevant_only=true'
                }

                if (messageIds !== undefined) {
                    const selectedMessageIds = messageIds.split(",")
                    selectedMessageIds.forEach((msgId) => {
                        url += `&selected_message_ids[]=${encodeURIComponent(msgId)}`
                    })
                }

                if (dataSource.searchText !== "") {
                    url += `&search_text=${dataSource.searchText}`
                }

                if (dataSource.salesmen.selected !== -1) {
                    url += `&salesman_id=${dataSource.salesmen.selected}`
                }

                if (dataSource.isRefresh) {
                    url += '&is_refresh=true'
                }

                const response = await api.get(url, appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(response.data)
                const offerMessagesWithKey = response.data["results"].map((row) => {
                    //console.log(row)
                    return {
                        ...row,
                        'key': row['message']['id'], /* rowKey="id" not working */
                        'showFullMsg': false,
                    }
                })
                setDataSource(draft => {
                    draft.offerMessages = offerMessagesWithKey
                    draft.firedMessageIds = response.data["fired_message_ids"]
                    draft.ignoredMessageIds = response.data["ignored_message_ids"]
                    draft.waIdDict = response.data["wa_id_dict"]
                    draft.pagination.total = response.data["count"]
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch offer messages!"
                })
            }

            setDataSource(draft => {
                draft.isLoading = false
            })
        }

        fetchOfferMessages()
        return () => {
            ourRequest.cancel()
        }
    }, [dataSource.pagination.current,
        dataSource.pagination.pageSize,
        dataSource.requestCount,
        dataSource.salesmen.selected,
        dataSource.timeRange,
        dataSource.firedFilter,
        dataSource.relevantOnly,
        appState.loggedIn,
    ])

    const listLoading = {
        spinning: dataSource.isLoading,
        indicator: <div style={{ minHeight: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin size="large" />
        </div>,
    }

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTo(0, 0)
        }
    }, [dataSource.pagination.current])

    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fireOfferMessage() {
            if (!appState.loggedIn) return

            if (dataSource.fireData.requestCount === 0) return

            setDataSource(draft => {
                draft.isLoading = true
            })

            try {
                const response = await api.post('fire_offer/', { 'message_id': dataSource.fireData.msgId, 'is_fire': dataSource.fireData.isFire, }, appState.token.post_config, { cancelToken: ourRequest.token })
                
                appDispatch({
                    type: "success",
                    data: response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: dataSource.fireData.isFire ? "Unable to fire message!" : "Unable to unfire message!"
                })
                const msgId = dataSource.fireData.msgId
                if (dataSource.firedMessageIds.includes(msgId)) {
                    const index = dataSource.firedMessageIds.indexOf(msgId)
                    setDataSource(draft => {
                        draft.firedMessageIds.splice(index, 1)
                    })
                } else {
                    setDataSource(draft => {
                        draft.firedMessageIds.push(msgId)
                    })
                }
            }

            setDataSource(draft => {
                draft.isLoading = false
            })
        }

        fireOfferMessage()
        return () => {
            ourRequest.cancel()
        }
    }, [dataSource.fireData.requestCount,])

    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function ignoreOfferMessage() {
            if (!appState.loggedIn) return

            if (dataSource.ignoreData.requestCount === 0) return

            setDataSource(draft => {
                draft.isLoading = true
            })

            try {
                const response = await api.post('ignore_offer/', { 'message_id': dataSource.ignoreData.msgId, 'is_ignore': dataSource.ignoreData.isIgnore, }, appState.token.post_config, { cancelToken: ourRequest.token })
                
                appDispatch({
                    type: "success",
                    data: response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: dataSource.ignoreData.isIgnore ? "Unable to ignore message!" : "Unable to unignore message!"
                })
                const msgId = dataSource.ignoreData.msgId
                if (dataSource.ignoredMessageIds.includes(msgId)) {
                    const index = dataSource.ignoredMessageIds.indexOf(msgId)
                    setDataSource(draft => {
                        draft.ignoredMessageIds.splice(index, 1)
                    })
                } else {
                    setDataSource(draft => {
                        draft.ignoredMessageIds.push(msgId)
                    })
                }
            }

            setDataSource(draft => {
                draft.isLoading = false
            })

            if (dataSource.relevantOnly) {
                setDataSource(draft => {
                    draft.isRefresh = true
                    draft.requestCount++
                })
            }
        }

        ignoreOfferMessage()
        return () => {
            ourRequest.cancel()
        }
    }, [dataSource.ignoreData.requestCount,])

    function onPageChange(page) {
        setDataSource(draft => {
            draft.selectedItemIndex = 0
            draft.pagination.current = page
            draft.isRefresh = false
        })
    }

    function refreshTable() {
        setDataSource(draft => {
            draft.isRefresh = true
            draft.requestCount++
        })
    }

    function displayOffersFromMessage(index) {
        setDataSource(draft => {
            draft.selectedItemIndex = index
        })
    }

    function onSalesmanChange(value) {
        setDataSource(draft => {
            draft.salesmen.selected = value
            draft.selectedItemIndex = 0
            draft.pagination.current = 1
            draft.isRefresh = true
        })
    }

    function onRangeChange(value) {
        setDataSource(draft => {
            draft.timeRange = value
            draft.selectedItemIndex = 0
            //draft.pagination.pageSize = value == "Today" ? 1000 : 20
            draft.pagination.current = 1
            draft.isRefresh = true
        })
    }

    function applyMsgSimThrChange() {
        setDataSource(draft => {
            draft.msgSimThrSent = dataSource.msgSimThr
            draft.selectedItemIndex = 0
            draft.pagination.current = 1
            draft.isRefresh = true
            draft.requestCount++
        })
    }

    function onSearch(value) {
        setDataSource(draft => {
            draft.searchText = value
            draft.selectedItemIndex = 0
            draft.pagination.current = 1
            draft.isRefresh = true
            draft.requestCount++
        })
    }

    function onMsgSimThrChange(value) {
        setDataSource(draft => {
            draft.msgSimThr = value
        })
    }
    function onMinNumGroupsChange(value) {
        setDataSource(draft => {
            draft.minNumGroups = value
        })
    }
    function onMinNumDMsChange(value) {
        setDataSource(draft => {
            draft.minNumDMs = value
        })
    }

    useEffect(() => {
        localStorage.setItem("dc_msgSimThr", dataSource.msgSimThr)
        localStorage.setItem("dc_minNumGroups", dataSource.minNumGroups)
        localStorage.setItem("dc_minNumDMs", dataSource.minNumDMs)
    }, [
        dataSource.msgSimThr,
        dataSource.minNumGroups,
        dataSource.minNumDMs,
    ])

    function openMessageModel(msgContent) {
        setGenericWarningData(draft => {
            draft.title = msgContent
            draft.modal.isOpen = true
        })
    }

    function toggleViewMsg(index) {
        const val = dataSource.offerMessages[index].showFullMsg
        setDataSource(draft => {
            draft.offerMessages[index].showFullMsg = !val
        })
    }

    function onSelectedMessageChange(msgId, e) {
        if (e.target.checked) {
            setDataSource(draft => {
                draft.selectedMessageIds.push(msgId)
            })
        } else {
            const index = dataSource.selectedMessageIds.indexOf(msgId)
            setDataSource(draft => {
                draft.selectedMessageIds.splice(index, 1)
            })
        }
    }

    function onFireFilterChange(e) {
        setDataSource(draft => {
            draft.firedFilter = e.target.checked
            draft.selectedItemIndex = 0
            draft.pagination.current = 1
            draft.isRefresh = true
        })
    }

    function onRelevantOnlyChange(e) {
        setDataSource(draft => {
            draft.relevantOnly = e.target.checked
            draft.selectedItemIndex = 0
            draft.pagination.current = 1
            draft.isRefresh = true
        })
    }

    function fireMessage(msgId) {
        if (appState.user.role === "SALESMAN") return
        setDataSource(draft => {
            draft.firedMessageIds.push(msgId)
            draft.fireData.msgId = msgId
            draft.fireData.isFire = true
            draft.fireData.requestCount++
        })
    }

    function unFireMessage(msgId) {
        if (appState.user.role === "SALESMAN") return
        const index = dataSource.firedMessageIds.indexOf(msgId)
        setDataSource(draft => {
            draft.firedMessageIds.splice(index, 1)
            draft.fireData.msgId = msgId
            draft.fireData.isFire = false
            draft.fireData.requestCount++
        })
    }

    function stopButtonColor(msgId) {
        return dataSource.ignoredMessageIds.includes(msgId) ? "red" : "grey"
    }

    function messageTextStyle(msgId) {
        return dataSource.ignoredMessageIds.includes(msgId) ? "line-through" : ""
    }

    function ignoreOrUnignoreMessage(msgId) {
        if (appState.user.role === "SALESMAN") return
        const index = dataSource.ignoredMessageIds.indexOf(msgId)
        if (index === -1) {
            setDataSource(draft => {
                draft.ignoredMessageIds.push(msgId)
                draft.ignoreData.msgId = msgId
                draft.ignoreData.isIgnore = true
                draft.ignoreData.requestCount++
            })
        } else {
            setDataSource(draft => {
                draft.ignoredMessageIds.splice(index, 1)
                draft.ignoreData.msgId = msgId
                draft.ignoreData.isIgnore = false
                draft.ignoreData.requestCount++
            })
        }
    }

    function onNavLivkClick() {
        setDataSource(draft => {
            draft.selectedMessageIds = []
        })
    }

    function getIndividualOffers(index) {
        const userToOfferMap = {}
        const currMsg = dataSource.offerMessages[index]
        if (currMsg) {
            for (const reach of currMsg.reaches) {
                const userName = reach.custom_user.full_name
                if (userName === "Dummy") {
                    continue
                }
                if (!userToOfferMap.hasOwnProperty(userName)) {
                    userToOfferMap[userName] = { bigGroups: [], smallGroups: [], dms: [] }
                }
                if (reach.is_group) {
                    let num_participants = 0
                    let isBigGroup = false
                    if (dataSource.waIdDict) {
                        num_participants = dataSource.waIdDict[reach.wa_id_for_dealer]
                        if (num_participants && num_participants > 30) {
                            isBigGroup = true
                        }
                    }
                    if (isBigGroup) {
                        userToOfferMap[userName].bigGroups.push([reach.chat_name, getLocalTime(reach.message_dealer_time), num_participants])
                    } else {
                        userToOfferMap[userName].smallGroups.push([reach.chat_name, getLocalTime(reach.message_dealer_time), num_participants])
                    }
                } else {
                    userToOfferMap[userName].dms.push([reach.chat_name, getLocalTime(reach.message_dealer_time), '-'])
                }
            }
        }
        let userToOfferMapSorted = Object.keys(userToOfferMap).sort().reduce(
            (obj, key) => { 
                obj[key] = userToOfferMap[key]
                return obj
            }, 
            {}
        )
        return userToOfferMapSorted
    }

    function displayReaches(index, userName) {
        let numBigGroups = 0
        let numSmallGroups = 0
        let numDMs = 0
        const userToOfferMap = getIndividualOffers(index)
        if (userToOfferMap.hasOwnProperty(userName)) {
            numBigGroups = userToOfferMap[userName].bigGroups.length
            numSmallGroups = userToOfferMap[userName].smallGroups.length
            numDMs = userToOfferMap[userName].dms.length
        }
        const numTotal = numBigGroups+numSmallGroups+numDMs
        const isNumGroupsNotOK = numBigGroups+numSmallGroups < dataSource.minNumGroups
        const isNumDMsNotOK = numDMs < dataSource.minNumDMs

        if (dataSource.salesmen.selected !== -1 || appState.user.role === "SALESMAN") {
            return (
                <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{width:'100px'}}>Big Groups:<strong style={{padding:4, borderRadius:2, backgroundColor: isNumGroupsNotOK ? "#FF4D4F" : "#73D673"}}>{numBigGroups}</strong></div>
                    <div style={{width:'100px'}}>Small Groups:<strong style={{padding:4, borderRadius:2, backgroundColor: isNumGroupsNotOK ? "#FF4D4F" : "#73D673"}}>{numSmallGroups}</strong></div>
                    <div style={{width:'100px'}}>DMs:<strong style={{padding:4, borderRadius:2, backgroundColor: isNumDMsNotOK ? "#FF4D4F" : "#73D673"}}>{numDMs}</strong></div>
                    <div style={{width:'100px'}}>Total:<strong style={{padding:4, borderRadius:2, backgroundColor: isNumGroupsNotOK || isNumDMsNotOK ? "#FF4D4F" : "#73D673"}}>{numTotal}</strong></div>
                </div>
            )
        }

        return (
            <table>
                <tbody>
                    <tr>
                        <td style={{padding:2, borderRadius:2}}>Big Grp:</td>
                        <td style={{padding:2, borderRadius:2, backgroundColor: isNumGroupsNotOK ? "#FF4D4F" : "#73D673"}}>
                            <strong>{numBigGroups}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td style={{padding:2, borderRadius:2}}>Sm. Grp:</td>
                        <td style={{padding:2, borderRadius:2, backgroundColor: isNumGroupsNotOK ? "#FF4D4F" : "#73D673"}}>
                            <strong>{numSmallGroups}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td style={{padding:2, borderRadius:2}}>DM:</td>
                        <td style={{padding:2, borderRadius:2, backgroundColor: isNumDMsNotOK ? "#FF4D4F" : "#73D673"}}>
                            <strong>{numDMs}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td style={{padding:2, borderRadius:2}}>Total:</td>
                        <td style={{padding:2, borderRadius:2, backgroundColor: isNumGroupsNotOK || isNumDMsNotOK ? "#FF4D4F" : "#73D673"}}>
                            <strong>{numTotal}</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    if (!appState.loggedIn) return <Login />

    return (
        <div className='App'>
            <SideMenu connected={props.connected}/>
            <Space direction="vertical" style={{paddingLeft:'12px'}}>
                <div style={{paddingTop:'12px', display:'flex'}}>
                    <div style={{marginLeft:'auto', display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <strong style={{marginRight:'10px'}}>{`${dataSource.pagination.total} messages found`}</strong>
                        <div><Search disabled={dataSource.isLoading} placeholder="Search in messages" onSearch={onSearch} enterButton allowClear style={{paddingRight:'25px'}} /></div>
                        <AntSelect
                            size="small"
                            disabled={dataSource.isLoading}
                            selected={dataSource.timeRange}
                            options={["Today", "Last_week", ]}
                            onChange={(newValue) => {
                                onRangeChange(newValue)
                            }}
                            width={125}
                        />
                        <div style={{width:'10px'}} />
                        {appState.user.role === "SALESMAN" ? "" :
                        <AntSelect
                            disabled={dataSource.salesmen.isLoading || dataSource.isLoading}
                            size="small"
                            options={dataSource.salesmen.data}
                            selected={dataSource.salesmen.selected}
                            onChange={onSalesmanChange}
                            width={200}/>}
                        <Button type="primary" disabled={dataSource.isLoading} style={{marginLeft:'10px'}} onClick={() => refreshTable()}>Refresh</Button>
                    </div>
                </div>
                <div style={{marginLeft: '0px', display:'flex', flexDirection:'row'}}>
                    <Space direction="vertical">
                        <Space style={{marginLeft:"15px"}} >
                            <Tooltip title="Message Similarity Threshold" placement="top"><strong>Msg Sim Thr:</strong></Tooltip>
                            <span>{dataSource.msgSimThrSent}</span>
                            <Slider style={{width: 100, }} disabled={dataSource.isLoading} value={dataSource.msgSimThr} onChange={onMsgSimThrChange} min={0.5} max={1} step={0.01}/>
                            <Button type="primary" disabled={dataSource.isLoading} onClick={() => applyMsgSimThrChange()}>Apply</Button>
                            <Checkbox style={{marginLeft:'15px'}} disabled={dataSource.isLoading} checked={dataSource.firedFilter} onChange={(e) => { onFireFilterChange(e) }}>Hot Only</Checkbox>
                            <Checkbox style={{marginLeft:'15px'}} disabled={dataSource.isLoading} checked={dataSource.relevantOnly} onChange={(e) => { onRelevantOnlyChange(e) }}>Relevant Only</Checkbox>
                        </Space>
                        {dataSource.selectedMessageIds.length > 0 ?
                            <NavLink style={{marginLeft:'15px'}} to={`/reaches/${dataSource.selectedMessageIds}`} target="_blank" rel="noopener noreferrer"
                                onClick={() => onNavLivkClick()}>
                                Show Selected Messages in New Tab
                            </NavLink>
                        : ""}
                    </Space>
                    <Space style={{marginLeft:'auto'}}>
                        <strong>Min Num Groups:</strong>
                        <Slider style={{width: 200 }} disabled={dataSource.isLoading} value={dataSource.minNumGroups} onChange={onMinNumGroupsChange} min={0} max={100} step={1}/>
                        <strong>Min Num DMs:</strong>
                        <Slider style={{width: 200 }} disabled={dataSource.isLoading} value={dataSource.minNumDMs} onChange={onMinNumDMsChange} min={0} max={100} step={1}/>
                    </Space>
                </div>
                <GenericWarningModal
                        state={genericWarningData}
                        setDataSource={setDataSource}
                        setState={setGenericWarningData}
                        notDisplayOkButton={true}
                        displayCopyButton={true}
                    />
                <div ref={listRef} className="chat-window" style={{height:'930px'}}>
                    <List
                        loading={listLoading}
                        className="message-list"
                        itemLayout="horizontal"
                        dataSource={dataSource.offerMessages}
                        renderItem={(message, index) => (
                            <List.Item
                                key={index}
                                style={{display: 'flex', justifyContent: 'space-between', backgroundColor: dataSource.selectedItemIndex === index ? '#F1D996' : index % 2 === 0 ? '#f0f0f0': '#fafafa', borderRadius:4, cursor:'pointer'}}
                                onClick={() => displayOffersFromMessage(index)}
                            >
                                <div style={{width: dataSource.salesmen.selected === -1 ? '600px' :'900px', display:'flex', flexDirection:'column'}}>
                                    <strong>{getLocalTime(message.last_sent_time, false)}</strong>
                                    <>
                                        <Space size="small" direction="horizontal">
                                            {dataSource.firedMessageIds.includes(message.message.id) ? <FireFilled style={{fontSize:'24px', color:"#FE5127"}} onClick={() => unFireMessage(message.message.id)} /> : <FireOutlined style={{fontSize:'24px', color:"#FFA33A"}} onClick={() => fireMessage(message.message.id)}/>}
                                            {appState.user.role === "SALESMAN" ? "" : <StopOutlined style={{fontSize:'20px', color:stopButtonColor(message.message.id)}} onClick={() => ignoreOrUnignoreMessage(message.message.id)} />}
                                            <Checkbox disabled={dataSource.showSelectedMessages} checked={dataSource.selectedMessageIds.includes(message.message.id)} onChange={(e) => { onSelectedMessageChange(message.message.id, e) }} />
                                            <p onClick={() => openMessageModel(message.message.content)} className="long-text-without-spaces-long-msg" style={{textAlign:'justify', maxWidth:'900px', textDecoration:messageTextStyle(message.message.id)}}>{cropText(message.message.content, 255)}</p>
                                        </Space>
                                        {message.showFullMsg ? message.similar_messages.map((similarMessage, indexSimilarMessages) => {
                                            return (
                                                <>
                                                    <strong>{getLocalTime(similarMessage.message_time, false)}</strong>
                                                    <Space size="small" direction="horizontal">
                                                        {dataSource.firedMessageIds.includes(similarMessage.id) ? <FireFilled style={{fontSize:'24px', color:"#FE5127"}} onClick={() => unFireMessage(similarMessage.id)} /> : <FireOutlined style={{fontSize:'24px', color:"#FFA33A"}} onClick={() => fireMessage(similarMessage.id)}/>}
                                                        {appState.user.role === "SALESMAN" ? "" : <StopOutlined style={{fontSize:'20px', color:stopButtonColor(similarMessage.id)}} onClick={() => ignoreOrUnignoreMessage(similarMessage.id)} />}
                                                        <Checkbox disabled={dataSource.showSelectedMessages} checked={dataSource.selectedMessageIds.includes(similarMessage.id)} onChange={(e) => { onSelectedMessageChange(similarMessage.id, e) }}/>
                                                        <p onClick={() => openMessageModel(similarMessage.content)} className="long-text-without-spaces-long-msg" style={{textAlign:'justify', maxWidth:'900px', textDecoration:messageTextStyle(similarMessage.id)}}>{cropText(similarMessage.content, 255)}</p>
                                                    </Space>
                                                </>
                                            )
                                        }) : ""}
                                        {message.similar_messages.length > 0 ? <span className='link-btn-style'
                                            onClick={() => {
                                                toggleViewMsg(index)
                                            }}>
                                            {message.showFullMsg ? "Hide similar messages" : `Show similar messages (${message.similar_messages.length})`}
                                        </span> : ""}
                                    </>
                                </div>
                                {appState.user.role === "SALESMAN" ?
                                    <div style={{width:'300px'}}>
                                        <div style={{height:'48px'}}><strong>{appState.user.username}</strong></div>
                                        <div>{ displayReaches(index, appState.user.username)}</div>
                                    </div> : 
                                    dataSource.salesmen.data.map((salesman, indexSalesman) => {
                                        if (salesman.full_name === "Dummy") return
                                        return (
                                            indexSalesman === 0 || (dataSource.salesmen.selected !== -1 && dataSource.salesmen.selected !== salesman.id) ? "" :
                                            <div key={indexSalesman} style={{width: dataSource.salesmen.selected === -1 ? '100px' :'300px'}}>
                                                <div style={{height:'48px'}}><strong>{salesman.full_name}</strong></div>
                                                <div>{ displayReaches(index, salesman.full_name)}</div>
                                            </div>
                                        )
                                    })
                                }
                            </List.Item>
                        )}
                    />
                </div>
                <Pagination
                    disabled={dataSource.isLoading}
                    showSizeChanger={dataSource.pagination.showSizeChanger}
                    current={dataSource.pagination.current}
                    total={dataSource.pagination.total}
                    pageSize={dataSource.pagination.pageSize}
                    onChange={onPageChange}
                    style={{marginTop: '5px', marginBottom:'25px', textAlign: 'right'}}
                />
            </Space>
            <ReachScreenOffers dataSource={dataSource} setDataSource={setDataSource} getIndividualOffers={getIndividualOffers}/>
        </div>
    )
}

export default ReachScreen