import { Button, Space, Badge, Spin } from "antd"
import { RedoOutlined } from '@ant-design/icons'


function DealerConnStatus(props) {

    const header = props.id in props.waState.header ? props.waState.header[props.id] : ""
    const message = props.id in props.waState.message ? props.waState.message[props.id] : ""
    const qrCode = props.id in props.waState.qrCode ? props.waState.qrCode[props.id] : ""
    const connected = props.id in props.waState.connected ? props.waState.connected[props.id] : false
    const connStr = props.id in props.waState.connStr ? props.waState.connStr[props.id] : "disconnected"

    return (
        <div className='grid-item'>
            {/*(connected || connStr === "connected") ?
                <Badge count={" "} size="small" color="#52c41a">
                    <div style={{width:"100%"}}></div>
                </Badge> :
                <Badge count={" "} size="small" color="#f5222d">
                    <div style={{width:"100%"}}></div>
                </Badge>
            */}
            <p>{props.dealer.full_name}</p>
            <p>{props.dealer.phone_number}</p>
            {/*<p>{connStr}</p>*/}
            <div>
                <h4>{header}</h4>
                <p id="msg" className='withprewrap'>
                    {message}
                </p>
                {qrCode === "" || qrCode === undefined || connected ? "" : 
                <Space direction="vertical">
                    <img src={qrCode} alt="QR Code" id="qrcode" width={30}/>
                    {/*<Button
                        className='btn-style-red'
                        style={{marginLeft: '14px', width:'272px'}}
                        onClick={props.onRefreshClick(props.dealer.phone_number)}
                        //size="small"
                        icon={<RedoOutlined />}
                    >
                        Refresh QR Code
                    </Button>*/}
                </Space>
                }
            </div>
        </div>
    )
}

export default DealerConnStatus